@import "./datatables.bundle.css";
@import "./style.bundle.css";
@import "./vendors.bundle.css";
@import url("https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&display=swap");

#root {
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial,
    "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
    "Noto Color Emoji";
  font-size: 14px;
}

a {
  text-decoration: none !important;
}

.HeightStretch {
  height: 100%;
}

.WidthStretch {
  width: 100%;
  height: 100%;
}

.onHover {
  cursor: pointer;
}

.alignLeft {
  float: left;
}

.alignRight {
  float: right;
}

.textCentred {
  text-align: center;
}

.profileImage {
  width: 40px;
  height: 40px;
}

.profileImageBig {
  width: 100px;
  height: 100px;
}

.profileFullName {
  padding: 9px !important;
}

.valideBtn {
  margin-top: 10px;
}

.center {
  margin: 0 auto;
  text-align: center;
}

.horizontalSpace {
  margin: 20px;
}

.btn-bg-login {
  background: #ff0000 !important;
}

.swal2-icon.swal2-warning {
  border-color: "#FFC70";
  color: "#FFC70";
}
.swal2-icon.swal2-error {
  border-color: #f1416c;
  color: #f1416c;
}
.swal2-icon.swal2-error [class^="swal2-x-mark-line"] {
  background-color: rgba(241, 65, 108, 0.75);
}
.swal2-icon.swal2-success {
  border-color: #50cd89;
  color: #50cd89;
}
.swal2-icon.swal2-success [class^="swal2-success-line"] {
  background-color: #50cd89;
}
.swal2-icon.swal2-success .swal2-success-ring {
  border-color: rgba(80, 205, 137, 0.3);
}
.swal2-icon.swal2-info {
  border-color: #7239ea;
  color: #7239ea;
}
.swal2-icon.swal2-question {
  border-color: #009ef7;
  color: #009ef7;
}

.swal2-popup .swal2-styled.swal2-confirm {
  color: #fff;
  background-color: #009ef7;
  border: 0;
  padding: calc(0.75rem + 1px) calc(1.5rem + 1px);
}

.swal2-container.swal2-center > .swal2-popup {
  gap: 22px;
}

.sweet2-ant-button {
  margin-right: 1rem;
}

.dropdown-menu {
  transform: translate(-1px, 21px) !important;
}

.ourSmartfoliosTable .ant-pro-table-alert {
  display: none;
  width: 0px !important;
}

.ourSmartfoliosTable .ant-table-selection-column {
  width: 0px !important;
}

.ourSmartfoliosTable .ant-table-row:hover {
  cursor: pointer;
}

.liveInvestmentsTable .ant-pro-table-alert {
  display: none;
}

.liveInvestmentsTable .ant-table-row:hover {
  cursor: pointer;
}

.ant-card-body:has(.no-padding) {
  padding: 0;
}

.ant-pro-card-body {
  padding: 0 !important;
  margin: 0 !important;
}

.f-antdTableHeaderStyle .ant-table-thead .ant-table-cell {
  white-space: nowrap;
}

::-webkit-scrollbar {
  width: 5px !important;
  height: 5px !important;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.ant-card {
  border-radius: 4px !important;
}

.ant-pro .ant-pro-layout .ant-pro-layout-content {
  padding: 16px !important;
}

.ant-btn {
  border-radius: 3px;
}

.ant-btn.ant-btn-lg {
  border-radius: 3px;
}

.padding-card-rahal .ant-card .ant-card-body {
  padding: 0 !important;
}

.sweet-warning {
  background-color: #4096ff;
}

.no-padding-tabs .ant-tabs-large .ant-tabs-nav .ant-tabs-tab {
  padding: 0 0 8px 0;
}

/* center everything on the page */
#page-container {
  max-height: 100vh;
  /* height: 100%; */
  margin: 0;
  /* display: flex; */
  /* flex-direction: column; */
  /* justify-content: center; */
  /* align-items: center; */
}

/* top logo */
#logo-top {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

/* bottom logo */
#logo-bottom {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  padding-bottom: 20px;
}

/* login form container */
#login-container {
  height: 95%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px;
  border: 1px solid rgba(166, 136, 54, 0.59);
  border-radius: 4px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  max-width: 400px;
  margin: 16px;
  width: 90%;
}

/* login form fields */
#login-form input[type="text"],
#login-form input[type="password"] {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}

/* login form submit button */
#login-form input[type="submit"] {
  background-color: #4caf50;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
}

/* login form error message */
#login-form .error {
  color: red;
  margin-bottom: 10px;
}

.sigCanvas {
  border: 1px solid #000;
  cursor: crosshair;
}

/* #test-pro-layout {
  margin: 0 !important;
  padding: 0 !important;
  border: 1px solid red;
} */

